import axios from "../instance";

export default {
  getAll: (transfer_type) => {
    return axios.api
      .get(`/transferProduct?transfer_type=${transfer_type}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/transferProduct/${id}`)
      .then((response) => response.data);
  },
  search: (body, transfer_type) => {
    return axios.api
      .post(`/transferProduct/search?transfer_type=${transfer_type}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/transferProduct", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/transferProduct/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/transferProduct/${id}`)
      .then((response) => response.data);
  },
};
