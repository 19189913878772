import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/transferee").then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/transferee/${id}`).then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post("/transferee/search", body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/transferee", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/transferee/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/transferee/${id}`)
      .then((response) => response.data);
  },
};
